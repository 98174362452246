
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overscroll-behavior: none;
  box-sizing: border-box;
  overflow: scroll;
}


.main-container {
  min-height: 100vh;
  /* Enable vertical scrolling within content area */
  
  background: radial-gradient(circle at top, #0b2718, #081426 20%, #000000 80%);
  background-attachment: fixed; 
  padding: 20px;
  align-items: center;
  flex-direction: column; /* Correct the property value */
}
  
  .file-container {
    max-height: 100%; /* Prevent the container from extending beyond the viewport height */
    overflow-y: auto; 

    background: linear-gradient(  rgba(20, 20, 44, 0.5), rgba(21, 51, 40, 0.4) , rgb(0, 0, 0,0.5) );;/* A light yellow background */

 
    background-attachment: fixed;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    width: 80%; /* or whatever width you prefer */
    max-width: 1000px; /* Max width to ensure it doesn't get too wide on larger screens */
    margin: 20px auto; /* Center it with automatic margins */
    padding: 20px; /* Padding inside the container */
}
.subfolder-button {
    padding: 10px 15px; 
    font-size: 15px;
    min-height: 20px;
  
     width: calc(100% - 30px); 
   
     margin-left: 30px;
     /* padding-left: 90px; /* More padding on the left to indent subfolder buttons */
      display: block; /* Make the button a block element to fill the width */
      /*width: 100%; /* Span the full width of its parent container */
      text-align: left; /* Align the text to the left */
       /* Add more padding for a larger click area and spacing */
      border: 0px solid rgb(215, 222, 241); /* Add a border to the button */
     /* border: none; /* Removes the border */
      border-radius: 4px; /* Slightly rounded corners on the buttons */
      background-color: rgba(194, 214, 241, 0.2); /* Light grey background to differentiate the button */
      color: rgb(255, 255, 255);/* Darker text for better readability */
      box-shadow: 2 10px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow for depth */
      transition: background-color 0.3s; /* Smooth transition for hover effect */
      /* Add other styles like padding, border, etc., as needed */
  }
  .subfolder-button:hover {
  /* Add a border to the button */
    background-color: rgb(127, 188, 245,0.5); /* Darker blue */
    color: rgb(0, 0, 0);
  }
  .user-button {

    border: 1px solid rgba(0, 0, 0, 0.1); /*rgb 15,56,35 - 15,40,75*/
    background-color: rgb(38, 45, 72,0.6);
    display: block; /* Make the button a block element to fill the width */
    width: 100%; /* Span the full width of its parent container */
    text-align: left; /* Align the text to the left */
    padding: 10px 15px; /* Add more padding for a larger click area and spacing */
    margin: 10px 0; /* Add margin to separate each button */
    
    border-radius: 8px; /* Slightly rounded corners on the buttons */
   /* background-color: #f9f9f9; /* Light grey background to differentiate the button */
    color: rgb(255, 255, 255); /* Darker text for better readability */
    /*box-shadow: 0px 2px 5px 1px rgba(28, 105, 228, 0.1); /* Optional: adds a slight shadow for depth */
    transition: border-color 0.2s ease ;
    transition: transform 0.2s ease-in-out;
   
  }
  .user-button:hover {
    transform: scale(1.01);

    border-color: rgba(138, 155, 223, 0.5);
  }

  .file-item {
  
   /* Lighter outline for the normal state */
   border: 1px solid transparent; /* Transparent outline by default */
    transition: border-color 0.3s ease-in-out;

    padding: 10px 15px; 
    margin-left: 70px;
    font-size: 15px;
    min-height: 40px;
    margin-top: 10px; /* Set the top margin */
    margin-bottom: 10px; /* Set the bottom margin */
     /* Adjust width to account for extra padding */
    text-decoration: none;
     /* padding-left: 90px; /* More padding on the left to indent subfolder buttons */
      display: block; /* Make the button a block element to fill the width */
      /*width: 100%; /* Span the full width of its parent container */
      text-align: left; /* Align the text to the left */
      padding: 8px 15px; /* Add more padding for a larger click area and spacing */
     /* Add a border to the button */
     /* border: none; /* Removes the border */
      border-radius: 6px; /* Slightly rounded corners on the buttons */
      background-color: rgba(52, 157, 119, 0.3); /* Light grey background to differentiate the button */
      color: rgb(255, 252, 252);
      box-shadow: 2 10px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow for depth */
      
  }
  .file-item:hover{
    box-shadow: 0 0 15px rgb(244, 244, 244,0.5);
  
    border-radius: 6px;
    
    border-color:rgba(52, 157, 119, 0.7); /* Darker outline color on hover */
   
  }
  
  ul {
    list-style-type: none;
    padding-left: 0; /* Removes default padding */
  }
  .complete-link {
   
    transition:  transform 0.2s ease-in;
    transition: box-shadow 0.2s ease-in;
    background: linear-gradient(to right, rgba(255, 96, 149, 0.9) , rgb(255, 210, 179)50%); /* Example gradient from peach to light pink */
    
    color: rgb(255, 255, 255); /* Adjust text color for better contrast if needed */
    padding: 12px 15px; 
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
     /* Adjust width to account for extra padding */
     min-height: 10px;
     margin-top: 0px; /* Set the top margin */
     margin-bottom: 0x; /* Set the bottom margin */
      /* Adjust width to account for extra padding */
     margin-left: 30px;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
     /* padding-left: 90px; /* More padding on the left to indent subfolder buttons */
      display:flex; /* Make the button a block element to fill the width */
      /*width: 100%; /* Span the full width of its parent container */
      text-align: left; /* Align the text to the left */
       /* Add more padding for a larger click area and spacing */
    
   
     
    
      background-size: 200% 200%; 
      border-radius: 4px; /* Slightly rounded corners on the buttons */

}
.complete-link:hover {
    box-shadow: 0 0 15px #ffcca2, /* Outer glow */
    0 0 5px #ffa600; /* Inner glow */

    transform: scale(1.01);
 
  }
  @keyframes movingGradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
 
  
.gradient-text {
  
  font-size: 20px;
  color: rgb(255, 255, 255) ;
    margin-left: 10%;
    margin-right: 10%;


    align-items: center;
    height: 100px; /* Adjust as needed */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logout-button {
  cursor: pointer;
  
  /* Add additional styling for the button as needed */
}
@keyframes shimmer {
    0% {
      background-position: -150% 0;
    }
    100% {
      background-position: 150% 0;
    }
  }




  .job-container {
    margin-top: 10px;
 
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Adds space between each job container */
  }
  

  .stop-job-button {
     padding: 10px 15px; 
    font-size: 15px;
    min-height: 20px;
  

     margin-left: 30px;
     /* padding-left: 90px; /* More padding on the left to indent subfolder buttons */
      display: block; /* Make the button a block element to fill the width */
      /*width: 100%; /* Span the full width of its parent container */
      text-align: left; /* Align the text to the left */
       /* Add more padding for a larger click area and spacing */
      border: 0px solid rgb(215, 222, 241); /* Add a border to the button */
     /* border: none; /* Removes the border */
      border-radius: 4px; /* Slightly rounded corners on the buttons */
      background-color: rgba(194, 214, 241, 0.2); /* Light grey background to differentiate the button */
      color: rgb(225, 242, 255,0.9);/* Darker text for better readability */
      box-shadow: 2 10px 10px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow for depth */
      transition: background-color 0.15s ease-in; /* Smooth transition for hover effect */
      /* Add other styles like padding, border, etc., as needed */
     
  
      margin-left: 10px;
    /* Keeps the Stop Job text on one line */
  }
  .stop-job-button:hover{

    background-color: rgba(255, 0, 0, 0.5);
    /* Darker outline color on hover */
   
  }
  
  


